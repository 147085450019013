
<template>
  <div class="fs full-screen">
    <div class="fs-content fs-content--shadow  fs-content--increased">

      <h1 class="fs-title" v-html="title"></h1>
      <p class="fs-desc" v-html="description"></p>

      <form class="a-form">
        <div class="a-form-box">
          <CountryCodeInput
            label="Страна"
            v-model="code"
          />
        </div>
        <div class="a-form-box">
          <PhoneInput
            label="Телефон"
            :code="code"
            :query-phone="this.$route.query.mobile"
            v-model="phone"
            @enter-press="submit"
          />
        </div>
      </form>
      <button
        @click.prevent="submit"
        class="fs-btn btn btn--blue"
        :disabled="(phone.length < code.length && $routeName.REGISTRATION === $route.name) || (!valid && $routeName.AUTHORIZATION === $route.name)"
        v-html="buttonText"
      >
      </button>
      <slot name="under-form"></slot>
    </div>
    <div class="fs-bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>

import PhoneInput from "@component/UI/Form/PhoneWithFixedCodeInput";
import CountryCodeInput from "@component/UI/Form/CountryCodeInput";
import * as pageName from '@router/variable';
import { parsePhoneNumber } from 'libphonenumber-js';

export default {
  name: "ModuleAuthorization",
  components: {
    PhoneInput,
    CountryCodeInput
  },
  props: {
    title: String,
    description: String,
    buttonText: {
      type: String,
      default: 'Отправить код'
    }
  },
  watch: {
    phone(v) {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        RequestManager.Register.checkMobile(v).then(response => {
          const { countryCallingCode, nationalNumber } = parsePhoneNumber(v);
          if (!response) {
            if (this.$route.name === pageName.REGISTRATION) return;
            this.$router.push({
              name: pageName.REGISTRATION,
              query: {
                code: countryCallingCode,
                mobile: nationalNumber
              }
            });
            return;
          }
          this.valid = response;
          if (this.$route.name === pageName.AUTHORIZATION) return;
          this.$router.push({
            name: pageName.AUTHORIZATION,
            query: {
              code: countryCallingCode,
              mobile: nationalNumber
            }
          });
        }).catch(() => {
          if (pageName.AUTHORIZATION === this.$route.name) this.valid = false;
        });
      }, 500);
    }
  },
  data() {
    return {
      code: '+7',
      phone: '',
      delayTimer: null,
      valid: false
    };
  },
  methods: {
    async submit() {
      if (this.phone) {

        try {
          await RequestManager.Auth.confirmPhone( this.phone );
          await this.$store.dispatch('auth/addMobile', this.phone);
          await this.$router.push({
            name: this.$routeName.CONFIRM_PHONE,
            query: {
              phone: this.phone,
              invite_id: this.$route.query.invite_id
            } });
        }
        catch (err) {
          console.error(err);
        }
      }
    }
  },
  mounted() {
    const { code } = this.$route.query;
    if (code) this.code = `+${code}`;
  }
};

</script>

<style lang="scss" scoped>
.full-screen {
  justify-content: space-between;

  .fs-content {
    margin: auto;
  }

  .fs-title {
    margin-bottom: 15px;
  }

  .fs-btn {
    margin-bottom: 25px;
  }

  .fs-bottom .link {
    margin-left: 5px;
  }

  .fs-policy {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.24px;
    color: rgba(0, 0, 0, 0.4);
  }

  .fs-policy a {
    color: #0C76DA;
  }
}

</style>

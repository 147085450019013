<template>
  <div class="a-form--input-wrapper" :disabled="!code">

    <label
      :for="`input-${_uid}`"
      class="a-form--label a-form--phone"
    >{{ label }}</label>

    <div class="a-form--input-inner">

      <transition name="fade">
        <label
          v-if="code"
          :for="`input-${_uid}`"
          class="a-form--phone-code"
        >{{ code }}</label>
      </transition>

      <input
        :id="`input-${_uid}`"
        class="a-form--input"
        type="tel"
        name="phone"
        v-mask="mask"
        :value="value"
        @input="e => onInput(e.target.value)"
        @keypress.enter="$emit('enter-press')"
      />

    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex';
import phoneInput from "@mixin/phoneInput";

export default {
  name: 'PhoneWithFixedCodeInput',
  mixins: [phoneInput],
  props: {
    label: String,
    code: {
      type: String,
      default: '',
    },
    phone: String,
    queryPhone: {
      type: String,
      default: ''
    }
  },
  computed: {
    phoneNumber() {
      return this.code + this.value;
    },
  },
  watch: {
    code(v) {
      this.$emit('change', this.phoneNumber);

      if (v) this.$el.querySelector('input').focus();
      else this.$el.querySelector('input').blur();

      this.value = '';
    },
  },
  data() {
    return {
      maskDefault: '### ### ## ##',
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.queryPhone) {
        this.value = this.queryPhone;
      }
    });
  },
  methods: {
    convertTemplateToMask(template) {

      let templateArr = template.split(' ');
      templateArr.splice(0, 1);

      template = templateArr.join(' ');

      return template.replace(/x/g, '#');
    },
  },
};
</script>

<style lang="scss" scoped>
  .a-form--input-wrapper {
    &[disabled] {
      pointer-events: none;
      opacity: .65;
    }
  }
  .a-form--input-inner {
    position: relative;
    display: flex;
  }
  .a-form--input {
    flex: 1;
    width: 100%;
    transition: width .25s linear;
  }
  .a-form--phone-code {
    font-size: 19px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.40px;
    padding: 5px 12px 10px 0;
    border-bottom: 1px solid #C4C3C3;
    &::after {
      content: '';
      background-color: #C3C3C3;
      width: 1px;
      height: 20px;
      display: inline-block;
      margin-left: 10px;
    }
  }
</style>

<template>
  <ModuleAuthorization
    title="Войти"
    description="Введите номер телефона, на который мы отправим СМС с кодом для входа"
  >

    <template #under-form>
      <router-link
        :to="{ name: $routeName.RESTORE_ACCESS }"
        class="link link--blue"
      >Восстановить доступ
      </router-link>
    </template>

    <template #bottom>
      <span>Нет аккаунта?</span>
      <router-link
        :to="{ name: $routeName.REGISTRATION }"
        class="link link--blue"
      >Зарегистрироваться
      </router-link>
    </template>

  </ModuleAuthorization>
</template>

<script>
import ModuleAuthorization from "@component/Module/Authorization";

export default {
  name: "Authorization",
  components: {
    ModuleAuthorization
  }
};
</script>

<style lang="scss" scoped>
.full-screen {
  height: 100vh;
  min-height: 800px;
}
</style>


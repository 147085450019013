<template>
  <div>
    <Select
      name="country"
      :items="values"
      v-on="$listeners"
      v-bind="$attrs"
    >
    </Select>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Select from "@component/UI/Form/Select";

export default {
  name: 'CountryCodeInput',
  components: { Select },
  computed: {
    ...mapState({
      countries: state => state.enums.countries
    }),
    values() {
      let values = [{ value: '', text: 'Не выбрано' }];

      if (!this.countries) return values;

      this.countries.forEach(country => {
        let { code, name } = country;
        values.push({ value: code, text: `${name} (${code})` });
      });

      return values;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
